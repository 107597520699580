import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payroll, PayrollSummary, PayrollTrip, Paystub, PayComponent } from '../models/payroll.model'
import { environment } from '../../../../environments/environment';
import { PayrateConfigs } from '../models/employee-payment-profile.model';

@Injectable({ providedIn: 'root' })
export class PaymentsService {

    resourceUrl: string = environment.apiEndpoint;

    constructor(private http: HttpClient) {
    }

    getPayrolls(organizationId: string, clientId: string): Observable<Payroll[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls`;
        return this.http.get<Payroll[]>(url);
    }

    getPayrollById(organizationId: string, clientId: string, payrollId: number): Observable<Payroll> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}`;
        return this.http.get<Payroll>(url);
    }

    generatePayroll(organizationId: string, clientId: string): Observable<Payroll> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/generate-payroll`;
        return this.http.post<Payroll>(url, null);
    }

    getPayrollSummary(organizationId: string, clientId: string, payrollId: number): Observable<PayrollSummary> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/get-payroll-summary`;
        return this.http.post<PayrollSummary>(url, null);
    }

    enablePayableTripsEdit(organizationId: string, clientId: string, payrollId: number): Observable<Payroll> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/enable-payable-trips-edit`;
        return this.http.post<Payroll>(url, null);
    }

    getPayableTrips(organizationId: string, clientId: String, payrollId: number, weekNumber: number, isApproved?: boolean): Observable<PayrollTrip[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payable-trips?payrollId=${payrollId}&weekNumber=${weekNumber}`;
        if (isApproved !== undefined) {
            url += `&isApproved=${isApproved}`
        }
        return this.http.get<PayrollTrip[]>(url);
    }

    postPayableTrips(organizationId: string, clientId: String, vehicleNumber: String, body: any) {
        console.log(body)
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payable-trips/${vehicleNumber}`;
        return this.http.post(url, body);
    }

    confirmPayableTrips(organizationId: string, clientId: string, payrollId: Number): Observable<Payroll> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/confirm-payable-trips`;
        return this.http.post<Payroll>(url, {});
    }

    savePaystubAmounts(organizationId: string, clientId: string, payrollId: Number, payStubs: Paystub[],changesCompleted: boolean): Observable<Payroll> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/save-paystub-payment-amounts`;
        if (changesCompleted) {
            url += `?changesCompleted=${changesCompleted}`
        }
        return this.http.post<Payroll>(url, payStubs);
    }

    saveRegularPayments(organizationId: string, clientId: string, payrollId: Number, payStubs: Paystub[], changesCompleted: boolean): Observable<Payroll> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/save-paystub-regular-payments`;
        if (changesCompleted) {
            url += `?changesCompleted=${changesCompleted}`
        }
        return this.http.post<Payroll>(url, payStubs);
    }

    getPaystubs(organizationId: string, clientId: string, payrollId: Number): Observable<Paystub[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/paystubs`;
        return this.http.get<Paystub[]>(url);
    }

    getPayComponents(organizationId: string, clientId: string): Observable<PayComponent[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/pay-components`;
        return this.http.get<PayComponent[]>(url);
    }

    getPayrateConfigs(organizationId: string, clientId: string): Observable<PayrateConfigs> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrate-configs`;
        return this.http.get<PayrateConfigs>(url);
    }

    findEmployeesWithoutPaystubs(organizationId: string, clientId: string, payrollId: Number): Observable<any[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/find-employees-without-paystubs`;
        return this.http.post<any[]>(url, {});
    }

    sendPaymentInformation(organizationId: string, clientId: String, payrollId: Number): Observable<any[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/send-payment-information`;
        return this.http.post<any[]>(url, { payrollId });
    }

    calculatePaystubAmounts(organizationId: string, clientId: string, payrollId: Number, paystub: Paystub): Observable<Paystub> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payrolls/${payrollId}/calculate-paystub-amounts`;
        return this.http.post<Paystub>(url, paystub);
    }

}
